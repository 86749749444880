.content {
    ul.pagination-list {
        list-style: none;
        margin-left: 0;
        margin-top: 0;

        li+li {
            margin-top: 0;
        }
    }
}

div.highlight {
    margin-bottom: 1rem;
}

@include desktop {
    .navbar-item {
        &.is-hoverable:hover {
            .navbar-dropdown {
                display: block;
            }
        }
    }
}

.contents {
    box-shadow: $box-shadow;
    padding: 1.5rem;
    margin-bottom: 3rem;
}