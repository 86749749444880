@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,500;0,700;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Oswald:wght@300&family=Roboto&display=swap');

$family-sans-serif: 'Alegreya', serif;
$primary: #A41034 !default;
$tabs-link-active-color: $primary;
$tabs-link-active-border-bottom-color: $primary;

// Disable the mobile breakpoint
//$mobile-enabled: false;
//$tablet-enabled: false;
//$desktop-enabled: false;
//$widescreen-enabled: false;
//$fullhd-enabled: false;

a{
  &:link{
    color: $primary;
    text-decoration: none;
  }
  &:visited{
    color: $primary;
    text-decoration: none;
  }
  &:hover{
    color: $primary;
    text-decoration: none;
  }
  &:active{
    color: $primary;
    text-decoration: none;
  }
}


@import "../node_modules/bulma/bulma.sass";
@import "layout";
@import "syntax";
@import "showcase";
@import "block-list";